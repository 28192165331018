import React from 'react'
import Bradcom from '../../Bradcom/Main'
import First from '../../Menteamdeatils/First'

const TeamDetails = () => {
  return (
    <>
        <Bradcom title={"Home"} subtitle={"Team Details"} common={"Team Details"}/>
        <First/>
    </>
  )
}

export default TeamDetails