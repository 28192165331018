import React from 'react'


const services = [
    {
        imgSrc: "assets/images/services/service-1.png",
        title: "Two Wheeler Finance",
        description: "Get quick loans on your used two wheeler and for purchase of new two wheelers "
    },
    {
        imgSrc: "assets/images/services/service-2.jpg",
        title: "Vehicle Finance",
        description: "Get quick loans on your used four wheelers and for purchase of new four wheelers "
    },
    {
        imgSrc: "assets/images/services/service-3.jpg",
        title: "SME Loans for Traders",
        description: "Get quick working capital loans for MSMEs and small businesses in the trading sector"
    }  
];

function First() {
    return (
        <>
            <section className="service-page pt-120 pb-60">
                <div className="container">
                    <div className="row">
                        {services.map((service, index) => (
                            <div className="col-lg-4 col-md-6 mb-60" key={index}>
                                <div className="service-one__card">
                                    <div className="service-one__image">
                                        <img src={service.imgSrc} alt="" />
                                    </div>
                                    <div className="service-one__content">
                                          <h3>{service.title}</h3>         
                                        <p>{service.description}</p>
                                       
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </section>
        </>
    )
}

export default First
