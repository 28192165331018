import React from 'react'

function Flexible() {
    const features = [
        {
            icon: "pylon-icon-assets",
            text: "Very Low Rates on All Loans"
        },
        {
            icon: "pylon-icon-verification",
            text: "99.9% Success Rate Guarantee"
        },
        {
            icon: "pylon-icon-finance",
            text: "Flexible with Your Repayments"
        }
    ];

    return (
        <>
            <section className="feature-one">
                <img src="assets/images/shapes/feature-shape-1-1.png" alt="" className="feature-one__shape-1" />
                <img src="assets/images/shapes/feature-shape-1-2.png" alt="" className="feature-one__shape-2" />
                <img src="assets/images/shapes/feature-shape-1-3.png" alt="" className="feature-one__shape-3" />
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="block-title text-left">
                                <p>Get to Know About</p>
                                <h2>Get your Loans processed quickly</h2>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <p className="block-text">Get  Two wheeler Loans, Vehicle Loans and SME Loans processed within a few business days</p>
                        </div>
                    </div>
                    <div className="row">
                        {features.map((feature, index) => (
                            <div className="col-lg-4" key={index}>
                                <div className="feature-one__box">
                                    <i className={feature.icon}></i>
                                    <p>{feature.text}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </section>
        </>
    )
}

export default Flexible
