import React from 'react'

function Why() {
    return (
        <>
            <section className="why-choose">
                <img src="assets/images/shapes/why-choose-shape-1-1.png" className="why-choose__shape-1" alt="" />
                <img src="assets/images/shapes/why-choose-shape-1-2.png" className="why-choose__shape-2" alt="" />
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="why-choose__image">
                                <p><i className="pylon-icon-investment"></i>26 years of working experience</p>
                                <img src="assets/images/resources/why-choose.jpeg" alt="" />
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="why-choose__content">
                                <div className="block-title text-left">
                                    <p>Our Benfits</p>
                                    <h2>Why Choose Us?</h2>
                                </div>
                                <p>Get  Two wheeler Loans, Vehicle Loans and SME Loans processed within a few business days.</p>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="why-choose__box">
                                            <h3><i className="fa fa-caret-right"></i>Professional Team</h3>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="why-choose__box">
                                            <h3><i className="fa fa-caret-right"></i>Quick Payments</h3>
                                        </div>
                                    </div>
                                </div>
                                <div className="why-choose__progress">
                                    <div className="why-choose__progress-top">
                                        <h3>Loan Process</h3>
                                        <span>90%</span>
                                    </div>
                                    <div className="why-choose__progress-bar">
                                        <span style={{ width: '90%' }} className="wow slideInLeft" data-wow-duration="1500ms"></span>
                                    </div>
                                </div>
                                <div className="why-choose__progress">
                                    <div className="why-choose__progress-top">
                                        <h3>Consultancy</h3>
                                        <span>76%</span>
                                    </div>
                                    <div className="why-choose__progress-bar">
                                        <span style={{ width: '76%' }} className="wow slideInLeft" data-wow-duration="1500ms"></span>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Why