import React from 'react'
import { Link}  from 'react-router-dom'

function First() {
    return (
        <>
            <section className="team-details">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <img src="assets/images/team/team-1.jpg" width="80%" className="img-fluid" alt="" />
                        </div>
                        <div className="col-lg-6">
                            <div className="team-details__content">
                                <h2>Mr.A Gowthaman</h2>
                                <span>Managing Director​</span>
                                <p>Mr.A Gowthaman carries Rich Professional experience in Vehicle Finance Space over 30 Years. He served as  President/ Business Head  with Shriram Finance  ( Commercial Vehicle finance / Construction Equipment finance.
During his short stint with Cholamandalamfinance , he managed Commercial Vehicle Finance business for South India.
He started the Business operations of M/s Indostar Capital in India as President / Business Head, was instrumental in Setting up over 200 Branches across India and managed business over Rs 8000 Crore.
</p>
                               {/* <div className="why-choose__progress">
                                    <div className="why-choose__progress-top">
                                        <h3>Loan Process</h3>
                                        <span>90%</span>
                                    </div>
                                    <div className="why-choose__progress-bar">
                                        <span style={{ width: '90%' }} className="wow slideInLeft" data-wow-duration="1500ms"></span>
                                    </div>
                                </div>
                                <div className="why-choose__progress">
                                    <div className="why-choose__progress-top">
                                        <h3>Consultancy</h3>
                                        <span>76%</span>
                                    </div>
                                    <div className="why-choose__progress-bar">
                                        <span style={{ width: '76%' }} className="wow slideInLeft" data-wow-duration="1500ms"></span>
                                    </div>
                                </div>
                                <div className="team-details__certificates">
                                    <img src="assets/images/team/team-certificate-1-1.jpg" alt="" />
                                    <img src="assets/images/team/team-certificate-1-2.jpg" alt="" />
                                </div>
                                <div className="team-details__social">
                                    <Link to="#" className="fab fa-facebook-square"></Link>
                                    <Link to="#" className="fab fa-twitter"></Link>
                                    <Link to="#" className="fab fa-pinterest-p"></Link>
                                    <Link to="#" className="fab fa-instagram"></Link>
                                </div>  */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container mt-5">
                    <div className="row">
                       {/* <div className="col-lg-6">
                            <img src="assets/images/team/team-1.jpg" width="80%" className="img-fluid" alt="" />
                            </div> */}
                        <div className="col-lg-6">
                            <div className="team-details__content">
                                <h2>Balaji p </h2>
                                <span>Zonal Manager​</span>
                                <p>Carries over 15 Years of Finance Experience in Vehicle Finance space. Was earlier associated with Shriram Finance, Cholamandalam ,&Indostar Capital.
</p>
                               {/* <div className="why-choose__progress">
                                    <div className="why-choose__progress-top">
                                        <h3>Loan Process</h3>
                                        <span>90%</span>
                                    </div>
                                    <div className="why-choose__progress-bar">
                                        <span style={{ width: '90%' }} className="wow slideInLeft" data-wow-duration="1500ms"></span>
                                    </div>
                                </div>
                                <div className="why-choose__progress">
                                    <div className="why-choose__progress-top">
                                        <h3>Consultancy</h3>
                                        <span>76%</span>
                                    </div>
                                    <div className="why-choose__progress-bar">
                                        <span style={{ width: '76%' }} className="wow slideInLeft" data-wow-duration="1500ms"></span>
                                    </div>
                                </div>
                                <div className="team-details__certificates">
                                    <img src="assets/images/team/team-certificate-1-1.jpg" alt="" />
                                    <img src="assets/images/team/team-certificate-1-2.jpg" alt="" />
                                </div>
                                <div className="team-details__social">
                                    <Link to="#" className="fab fa-facebook-square"></Link>
                                    <Link to="#" className="fab fa-twitter"></Link>
                                    <Link to="#" className="fab fa-pinterest-p"></Link>
                                    <Link to="#" className="fab fa-instagram"></Link>
                                </div>  */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container mt-5">
                    <div className="row">
                       {/* <div className="col-lg-6">
                            <img src="assets/images/team/team-1.jpg" width="80%" className="img-fluid" alt="" />
                            </div> */}
                        <div className="col-lg-6">
                            <div className="team-details__content">
                                <h2>R Settu </h2>
                                <span>Zonal Manager ​</span>
                                <p>Carries over 15 years of Finance Experience in Vehicle finance space. Was earlier associated with Shriram Finance.
                                    </p>
                               {/* <div className="why-choose__progress">
                                    <div className="why-choose__progress-top">
                                        <h3>Loan Process</h3>
                                        <span>90%</span>
                                    </div>
                                    <div className="why-choose__progress-bar">
                                        <span style={{ width: '90%' }} className="wow slideInLeft" data-wow-duration="1500ms"></span>
                                    </div>
                                </div>
                                <div className="why-choose__progress">
                                    <div className="why-choose__progress-top">
                                        <h3>Consultancy</h3>
                                        <span>76%</span>
                                    </div>
                                    <div className="why-choose__progress-bar">
                                        <span style={{ width: '76%' }} className="wow slideInLeft" data-wow-duration="1500ms"></span>
                                    </div>
                                </div>
                                <div className="team-details__certificates">
                                    <img src="assets/images/team/team-certificate-1-1.jpg" alt="" />
                                    <img src="assets/images/team/team-certificate-1-2.jpg" alt="" />
                                </div>
                                <div className="team-details__social">
                                    <Link to="#" className="fab fa-facebook-square"></Link>
                                    <Link to="#" className="fab fa-twitter"></Link>
                                    <Link to="#" className="fab fa-pinterest-p"></Link>
                                    <Link to="#" className="fab fa-instagram"></Link>
                                </div>  */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container mt-5">
                    <div className="row">
                      {/*  <div className="col-lg-6">
                            <img src="assets/images/team/team-1.jpg" width="80%" className="img-fluid" alt="" />
                            </div> */}
                        <div className="col-lg-6">
                            <div className="team-details__content">
                                <h2>P Subramanian </h2>
                                <span>Who joined as a consultant ​</span>
                                <p>Carries over 30 years of experience in an NBFC Industry. He was earlier associated with Fidelity Finance Ltd, Alpic Finance Ltd, Shriram Finance and Indostar Capital Finance Ltd.
</p>
                               {/* <div className="why-choose__progress">
                                    <div className="why-choose__progress-top">
                                        <h3>Loan Process</h3>
                                        <span>90%</span>
                                    </div>
                                    <div className="why-choose__progress-bar">
                                        <span style={{ width: '90%' }} className="wow slideInLeft" data-wow-duration="1500ms"></span>
                                    </div>
                                </div>
                                <div className="why-choose__progress">
                                    <div className="why-choose__progress-top">
                                        <h3>Consultancy</h3>
                                        <span>76%</span>
                                    </div>
                                    <div className="why-choose__progress-bar">
                                        <span style={{ width: '76%' }} className="wow slideInLeft" data-wow-duration="1500ms"></span>
                                    </div>
                                </div>
                                <div className="team-details__certificates">
                                    <img src="assets/images/team/team-certificate-1-1.jpg" alt="" />
                                    <img src="assets/images/team/team-certificate-1-2.jpg" alt="" />
                                </div>
                                <div className="team-details__social">
                                    <Link to="#" className="fab fa-facebook-square"></Link>
                                    <Link to="#" className="fab fa-twitter"></Link>
                                    <Link to="#" className="fab fa-pinterest-p"></Link>
                                    <Link to="#" className="fab fa-instagram"></Link>
                                </div>  */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section >
                <div className="container mt-5">
                    <div className="row">
                       {/* <div className="col-lg-6">
                            <img src="assets/images/team/team-1.jpg" width="80%" className="img-fluid" alt="" />
                            </div> */}
                        <div className="col-lg-6">
                            <div className="team-details__content">
                                <h2>J Venkatesh </h2>
                                <span>Chief Manager​</span>
                                <p>Carries over 10 years of experience in Loan Originating System  and Loan Mgt System. He was earlier associated with Equitas Small Finance Ltd and Indostar Capital Finance Ltd.

</p>
                               {/* <div className="why-choose__progress">
                                    <div className="why-choose__progress-top">
                                        <h3>Loan Process</h3>
                                        <span>90%</span>
                                    </div>
                                    <div className="why-choose__progress-bar">
                                        <span style={{ width: '90%' }} className="wow slideInLeft" data-wow-duration="1500ms"></span>
                                    </div>
                                </div>
                                <div className="why-choose__progress">
                                    <div className="why-choose__progress-top">
                                        <h3>Consultancy</h3>
                                        <span>76%</span>
                                    </div>
                                    <div className="why-choose__progress-bar">
                                        <span style={{ width: '76%' }} className="wow slideInLeft" data-wow-duration="1500ms"></span>
                                    </div>
                                </div>
                                <div className="team-details__certificates">
                                    <img src="assets/images/team/team-certificate-1-1.jpg" alt="" />
                                    <img src="assets/images/team/team-certificate-1-2.jpg" alt="" />
                                </div>
                                <div className="team-details__social">
                                    <Link to="#" className="fab fa-facebook-square"></Link>
                                    <Link to="#" className="fab fa-twitter"></Link>
                                    <Link to="#" className="fab fa-pinterest-p"></Link>
                                    <Link to="#" className="fab fa-instagram"></Link>
                                </div>  */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default First