import { Link } from 'react-router-dom';

import React ,{ useState , useEffect } from 'react'

function Loans() {

    const [emiVal, setEmival] = useState()
    const [TotalVal, setTotalVal] = useState()
    const [slider1, setSlider1] = useState(1000)
    const [slider2, setSlider2] = useState(1)

    useEffect(() => {
        if (slider1 > 0 && slider2 > 0) {
            emiCalculate()
        }
    }, [slider1, slider2])

    const emiCalculate = () => {
        const interestRate = 15;
        const interestRatePercent = parseInt(interestRate, 10) / 100;
        const totalPay = slider1 * interestRatePercent + parseInt(slider1, 10);
        const monthlyPay = totalPay / parseInt(slider2,10);
        
        setEmival(monthlyPay)
        setTotalVal(totalPay)
    }

    return (
        <>
            <section className="about-one">
                <div className="container pb-5">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="about-one__content">
                                <div className="block-title text-left">
                                    <p>Company Introductions</p>
                                    <h3>Our journey </h3>
                                </div>
                            { /*    <p className="about-one__text">Lorem ipsum is simply dolor sit amet, consectetur adipisicing text elit.</p> */}
                                <p>We are Registered Non Banking Finance Company, Registered under Reserve Bank of India on 26th February 1996 having our  Registered office at Namakkal, Tamilnadu& Administrative office at Chennai,TN.​</p> 
                            </div>
                        </div>


                        <div className="col-lg-6">
                            <div className="about-one__content">
                                <div className="block-title text-left">
                                   
                                    <h3>Business Commencement </h3>
                                </div>
                            { /*    <p className="about-one__text">Lorem ipsum is simply dolor sit amet, consectetur adipisicing text elit.</p> */}
                                <p>Trancity  Finance Limited , reinvented the business Strategy , appointed professional team and commenced Business from the month April’2023
We opened our First Branch at Thirukoviloor on 24th April 2023, followed by Villupuram, Kallakurichi and Rasipuram.
We have acquired over 1000 Customers in Short Span of time 
​</p> 
                            </div>
                        </div>
                      
                    </div>
                </div>
            </section>
        </>
    )
}

export default Loans