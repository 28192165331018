import BGPH1 from '../../assets/images/main-slider/main-slider-1-1.jpeg'
import BGPH2 from '../../assets/images/main-slider/main-slider-1-2.jpeg'
import BGPH3 from '../../assets/images/main-slider/main-slider-2-1.jpeg'
import BGPH4 from '../../assets/images/main-slider/main-slider-2-2.jpeg'

import { Navigation, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Link }  from 'react-router-dom'
import React  from 'react'

function Sliderone() {
    return (
        <>
            <section className="main-slider">
                <Swiper className="swiper-container thm-swiper__slider"
                    modules={[Navigation, Autoplay]}
                    slidesPerView={1}
                    loop={true}
                    navigation={{
                        nextEl: "#main-slider__swiper-button-next",
                        prevEl: "#main-slider__swiper-button-prev"
                    }}
                    autoplay={{ delay: 5000 }}
                >
                    <div className="swiper-wrapper">
                        <SwiperSlide>
                            <div className="swiper-slide">
                                <div className="image-layer" style={{ backgroundImage: `url(${BGPH1})` }}></div>
                                <div className="container">
                                    <div className="row">
                                        <div className="col-xl-6 col-lg-6">
                                            <p>Simple & Secure Payment Process</p>
                                            <h2>Connecting
                                                All Your Loan
                                                Needs</h2>
                                            <Link to="/contact" className=" thm-btn">Apply For Loan</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="swiper-slide">
                                <div className="image-layer" style={{ backgroundImage: `url(${BGPH2})` }}></div>
                                <div className="container">
                                    <div className="row">
                                        <div className="col-xl-6 col-lg-6">
                                            <p>Simple & Secure Payment Process</p>
                                            <h2>Connecting
                                                All Your Loan
                                                Needs</h2>
                                            <Link to="/contact" className=" thm-btn">Apply For Loan</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="swiper-slide">
                                <div className="image-layer" style={{ backgroundImage: `url(${BGPH3})` }}></div>
                                <div className="container">
                                    <div className="row">
                                        <div className="col-xl-6 col-lg-6">
                                            <p>Simple & Secure Payment Process</p>
                                            <h2>Connecting
                                                All Your Loan
                                                Needs</h2>
                                            <Link to="/contact" className=" thm-btn">Apply For Loan</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="swiper-slide">
                                <div className="image-layer" style={{ backgroundImage: `url(${BGPH4})` }}></div>
                                <div className="container">
                                    <div className="row">
                                        <div className="col-xl-6 col-lg-6">
                                            <p>Simple & Secure Payment Process</p>
                                            <h2>Connecting
                                                All Your Loan
                                                Needs</h2>
                                            <Link to="/contact" className=" thm-btn">Apply For Loan</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                    </div>


                    <div className="main-slider__nav">
                        <div className="swiper-button-prev" id="main-slider__swiper-button-next"><i className="pylon-icon-left-arrow"></i></div>
                        <div className="swiper-button-next" id="main-slider__swiper-button-prev"><i className="pylon-icon-right-arrow"></i></div>
                    </div>

                </Swiper>
                <div className="feature-two">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-duration="1500ms">
                                <div className="feature-two__box">
                                    <i className="pylon-icon-consumer-behavior"></i>
                                    <p>Quick Payment
                                        Process</p>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-duration="1500ms">
                                <div className="feature-two__box">
                                    <i className="pylon-icon-point-of-sale"></i>
                                    <p>No Prepayment
                                        Fees</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Sliderone