import { Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import React  from 'react'

function Business() {
  return (
    <>
        <div className="client-carousel pt-50 pb-120 client-carousel__has-border-top">
            <div className="container">
            <div className="block-title text-center">
                        <h3>BUSINESS ASSOCIATES</h3>
                    </div>
                <Swiper className="thm-swiper__slider swiper-container" 
                    modules={[Autoplay]}
                    spaceBetween={0}
                    slidesPerView={1}
                    loop={true}
                    autoplay={{delay:5000}}
                    breakpoints={{
                        "376": {
                            "spaceBetween": 30,
                            "slidesPerView": 2
                        },
                        "576": {
                            "spaceBetween": 30,
                            "slidesPerView": 3
                        },
                        "768": {
                            "spaceBetween": 30,
                            "slidesPerView": 3
                        },
                        "992": {
                            "spaceBetween": 30,
                            "slidesPerView": 4
                        },
                        "1200": {
                            "spaceBetween": 30,
                            "slidesPerView": 5
                        }
                    }}>
                        
                    <div className="swiper-wrapper">
                        <SwiperSlide>
                        <div className="swiper-slide">
                            <img src="assets\images\business\b-1.jpg" alt="" />
                           
                        </div>
                        </SwiperSlide>
                        <SwiperSlide>
                        <div className="swiper-slide">
                            <img src="assets\images\business\b-2.jpg" alt="" />
                            
                        </div>
                        </SwiperSlide>
                        <SwiperSlide>
                        <div className="swiper-slide">
                            <img src="assets/images/business/b-3.jpg" alt="" />
                        </div>
                        </SwiperSlide>
                        <SwiperSlide>
                        <div className="swiper-slide">
                            <img src="assets/images/business/b-4.jpg" alt="" />
                        </div>
                        </SwiperSlide>
                        <SwiperSlide>
                        <div className="swiper-slide">
                            <img src="assets/images/business/b-5.jpg" alt="" />
                        </div>
                        </SwiperSlide>
                        <SwiperSlide>
                        <div className="swiper-slide">
                            <img src="assets/images/business/b-6.jpg" alt="" />
                        </div>
                        </SwiperSlide>
                        <SwiperSlide>
                        <div className="swiper-slide">
                            <img src="assets/images/business/b-7.jpg" alt="" />
                        </div>
                        </SwiperSlide>
                     {/* <SwiperSlide>
                        <div className="swiper-slide">
                            <img src="assets/images/resources/brand-1-1.png" alt="" />
                        </div>
                        </SwiperSlide>
                        <SwiperSlide>
                        <div className="swiper-slide">
                            <img src="assets/images/resources/brand-1-1.png" alt="" />
                        </div>
                        </SwiperSlide>
                        <SwiperSlide>
                        <div className="swiper-slide">
                            <img src="assets/images/resources/brand-1-1.png" alt="" />
                        </div>
                </SwiperSlide>  */}
                    </div>
                </Swiper>
            </div>
        </div>
    </>
  )
}

export default Business